import React, { useState,useContext } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import "./stylesModal.css";
import InputFile from "../InputFile";
import { UserContext } from '../../UserContext.js';

export default function ModalAddExpe({ title, cod, description, isOpen, onClose, user, showToast }) {
    const { anio,mes } = useParams();
    const { userData, setUserData } = useContext(UserContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [options, setOptions] = useState({
        SelectedAval:''
    });
    const initialFormData={
        DNI: user?.DNI || '',
        APE_PAT: user?.APE_PAT || '',
        APE_MAT: user?.APE_MAT || '',
        FRECUENCIA:'',
        SCORE:'',
        NUMENTIDADES:'',
        CAPACIDADPAGO:'',
        ENDEUDAMIENTO:'',
        TIPO_EXPEDIENTE:'',
        GARA_FRECUENCIA:'',
        GARA_SCORE:'',
        GARA_NUMENTIDADES:'',
        GARA_CAPACIDADPAGO:'',
        GARA_ENDEUDAMIENTO:'',
        SelectedAval:'',
    }
    
    const [formData, setFormData] = useState(initialFormData);

    const [files, setFiles] = useState({
        'a_otros_solicitud_credito':null,
        'a_otros_reporte_sentinel':null,
        'a_otros_dni':null,
        'a_otros_cronograma_pago':null,
        'a_otros_reporte_ubicacion':null,
        'a_otros_fotos_negocio':null,
        'a_otros_evaluacion_credito':null,
        'a_otros_evidencias_adicionales':null,

        'b_aval_dni_titular':null,
        'b_aval_reporte_sentinel':null,
        'b_aval_recibo_servicio':null,
        'b_aval_documentos':null,

        'garantia_solicitud_credito':null,
        'garantia_reporte_sentinel':null,
        'garantia_dni':null,
        'garantia_Certificado_deposito_contrato':null
    });

    const handleFileChangeIn = (id, file) => {
        setFiles((prevFiles) => ({
            ...prevFiles,
            [id]: file
        }));
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id.toUpperCase()]: value
        }));
    };

    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        setOptions(prevOptions => ({
            ...prevOptions,
            [name]: value
        }));
    };

    const fetchSocio = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-sql/api/verifi-socio', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify(formData)
            });
            const socioData = await response.json();
            if (socioData) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    APE_PAT: socioData[0]['RAZON_SOCIAL'] || '',
                }));
                setIsSubmitting(false);
            }
        } catch (error) {
            console.error('Error fetching socio:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const formDataWithFiles = new FormData();
            // Agrega los campos del formulario
            formDataWithFiles.append('DNI', formData.DNI);
            formDataWithFiles.append('APE_PAT', formData.APE_PAT);
            formDataWithFiles.append('AGENCIA', userData.AGENCIA);
            formDataWithFiles.append('USER', userData.DNI);
            formDataWithFiles.append('ANIO', anio);
            formDataWithFiles.append('MES', mes);
            formDataWithFiles.append('SCORE',formData.SCORE);
            formDataWithFiles.append('NUMENTIDADES',formData.NUMENTIDADES);
            formDataWithFiles.append('CAPACIDADPAGO',formData.CAPACIDADPAGO);
            formDataWithFiles.append('ENDEUDAMIENTO',formData.ENDEUDAMIENTO);
            formDataWithFiles.append('FRECUENCIA',formData.FRECUENCIA);
            /**PARA GARANTIA LIQUIDA */
            formDataWithFiles.append('GARA_FRECUENCIA',formData.GARA_FRECUENCIA);
            formDataWithFiles.append('GARA_SCORE',formData.GARA_SCORE);
            formDataWithFiles.append('GARA_NUMENTIDADES',formData.GARA_NUMENTIDADES);
            formDataWithFiles.append('GARA_CAPACIDADPAGO',formData.GARA_CAPACIDADPAGO);
            formDataWithFiles.append('GARA_ENDEUDAMIENTO',formData.GARA_ENDEUDAMIENTO);
            /**VER TIPO DE DOCUMENTO */
            formDataWithFiles.append('TIPO_EXPEDIENTE',formData.TIPO_EXPEDIENTE);

            // Agrega los archivos seleccionados
            Object.keys(files).forEach((key) => {
                formDataWithFiles.append(key, files[key]);
            });
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/enviar_doc', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: formDataWithFiles
            });
            const result = await response.json();
            if (result.status === true) {
                showToast('existe');
                setFormData(initialFormData);
                Array.from(document.querySelectorAll('input[type="file"]')).forEach(input => (input.value = ""));
                onClose();
            } else {
                showToast('user_add');
            }
            setIsSubmitting(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            showToast('error_permisos');
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content lg:w-[70%] smm:w-[90%] max-h-[90vh] overflow-y-auto">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 lg:text-xl smm:text-sm font-semibold text-primary-800'>{title}</h2>
                </div>
                <form className="w-full mx-auto" onSubmit={handleSubmit}>
                    <div id='buscadorSocio' className='grid lg:grid-cols-4 lg:gap-4 mt-4 smm:grid-cols-3 smm:gap-2 '>
                        <div className="">
                            <label htmlFor="DNI" className="block lg:mb-2 smm:mb-0 text-sm font-medium text-primary-900">DNI</label>
                            <input
                                type="number"
                                id="DNI"
                                value={formData.DNI}
                                onChange={handleChange}
                                onBlur={fetchSocio}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 lg:text-sm smm:text-[11px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="DNI"
                            />
                        </div>
                        <div className="col-span-2">
                            <label htmlFor="APE_PAT" className="block lg:mb-2 smm:mb-0 text-sm font-medium text-primary-900">SOCIO</label>
                            <input
                                type="text"
                                id="APE_PAT"
                                value={formData.APE_PAT}
                                onChange={handleChange}
                                className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 lg:text-sm smm:text-[10px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Apellido Paterno" disabled
                            />
                        </div>
                        {formData.APE_PAT && (<>
                        <div className="lg:w-full grid lg:grid-cols-1 lg:col-span-1 smm:col-span-2 lg:mt-0 smm:mt-1">
                            <label htmlFor="" className="block lg:mb-2 smm:mb-0 text-sm font-medium text-primary-900">TIPO DE DOCUMENTO</label>
                            <select id={'TIPO_EXPEDIENTE'} value={formData.TIPO_EXPEDIENTE} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                <option >Tipo de producto</option>
                                <option value="GARANTIA">Garantia liquida</option>
                                <option value="OTROS">Otros productos</option>
                            </select>
                        </div>
                        </>)}
                    </div>
                    
                    {formData.TIPO_EXPEDIENTE==='OTROS'?<>  
                    <div id='inputFiles' className="smm:mt-2 lg:mt-0">
                        <label htmlFor="" className="text-[12px] text-primary-800 font-bold">Necesitamos conocer estos datos del crédito para el socio?</label>
                        <div className="grid lg:grid-cols-5 lg:gap-1 smm:grid-cols-3 smm:gap-1">
                            <div className="smm:col-span-2 lg:col-span-1">
                                <select id={'FRECUENCIA'} value={formData.FRECUENCIA} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                    <option >Frecuencia de pago</option>
                                    <option value="DIARIO">DIARIO</option>
                                    <option value="SEMANAL">SEMANAL</option>
                                    <option value="QUINCENAL">QUINCENAL</option>
                                    <option value="MENSUAL">MENSUAL</option>
                                </select>
                            </div>
                            <input placeholder="SCORE" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="SCORE"
                            value={formData.SCORE}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="# DE ENTIDADES" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="NUMENTIDADES"
                            value={formData.NUMENTIDADES}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="CAPACIDAD DE PAGO" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="CAPACIDADPAGO"
                            value={formData.CAPACIDADPAGO}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="ENDEUDAMIENTO TOTAL" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="ENDEUDAMIENTO"
                            value={formData.ENDEUDAMIENTO}
                            onChange={handleChange}
                            type="number"/>
                        </div>
                        <div  className='grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 mt-4'>
                            <div className="mb-2">
                                <label htmlFor="solicitud_credito" className="block mb-2 text-sm font-medium text-primary-900">Solicitud de crédito</label>
                                <InputFile id={'a_otros_solicitud_credito'} title={'Solicitud de crédito'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="reporte_sentinel" className="block mb-2 text-sm font-medium text-primary-900">Reporte sentinel</label>
                                <InputFile id={'a_otros_reporte_sentinel'} title={'Reporte sentinel'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="dni" className="block mb-2 text-sm font-medium text-primary-900">DNI</label>
                                <InputFile id={'a_otros_dni'} title={'DNI'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="cronograma_pago" className="block mb-2 text-sm font-medium text-primary-900">Cronograma de pago</label>
                                <InputFile id={'a_otros_cronograma_pago'} title={'Cronograma de pago'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                        </div>
                        <div className='grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 mt-4'>
                            <div className="mb-2">
                                <label htmlFor="reporte_ubicacion" className="block mb-2 text-sm font-medium text-primary-900">Reporte ubicación</label>
                                <InputFile id={'a_otros_reporte_ubicacion'} title={'Reporte ubicación'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="fotos_negocio" className="block mb-2 text-sm font-medium text-primary-900">Fotos del negocio</label>
                                <InputFile id={'a_otros_fotos_negocio'} title={'Fotos del negocio'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="evaluacion_credito" className="block mb-2 text-sm font-medium text-primary-900">Evaluación de crédito</label>
                                <InputFile id={'a_otros_evaluacion_credito'} title={'Evaluación de crédito'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="evidencias_adicionales" className="block mb-2 text-sm font-medium text-primary-900">Evidencias adicionales</label>
                                <InputFile id={'a_otros_evidencias_adicionales'} title={'Evidencias adicionales'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 justify-center items-center ml-8 my-2">
                            <label htmlFor="" className="text-[10px] text-primary-800">¿El socio cuenta con aval o fiador solidario?</label>
                            <div className="col-span-3 inline-flex gap-8">
                                <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                    <input className="my-1" type="radio" name="SelectedAval" value="SI" 
                                    checked={options.SelectedAval === 'SI'}
                                    onChange={handleOptionChange}
                                    />
                                    SI
                                </label>
                                <label className="flex justify-center items-center gap-2 text-[10px] text-primary-800">
                                    <input className="my-1" type="radio" name="SelectedAval" value="NO" 
                                    checked={options.SelectedAval === 'NO'}
                                    onChange={handleOptionChange}
                                    />
                                    NO
                                </label>
                            </div>
                        </div>
                    {options.SelectedAval==='SI'?
                    <div>
                        <div className="border-2 border-primary-800 p-2 rounded-xl">
                            <label htmlFor="" className="uppercase text-[10px] font-bold text-primary-800">! Es importate el ingreso de los siguientes documentos</label>
                            <p className="text-[10px] text-primary-800"><span className="font-bold">DNI:</span> DNI DEL AVAL Y CONYUGE</p>
                            <p className="text-[10px] text-primary-800"><span className="font-bold">Reporte sentinel:</span> SENTINEL DEL AVAL</p>
                            <p className="text-[10px] text-primary-800"><span className="font-bold">Recibo de servicios:</span> RECIBO DE SERVICIOS DE AGUA O LUZ</p>
                            <p className="text-[10px] text-primary-800"><span className="font-bold">Documentos:</span> DOCUMENTOS QUE ACREDITAN INGRESOS </p>
                            <p className="text-[10px] text-primary-800"><span className="font-bold">Documentos:</span> DOCUMENTOS QUE ACREDITAN PROPIEDAD</p>
                        </div>
                        <div  className='grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 mt-4'>
                            <div className="mb-2">
                                <label htmlFor="b_aval_dni_titular" className="block mb-2 text-sm font-medium text-primary-900 uppercase">DNI</label>
                                <InputFile id={'b_aval_dni_titular'} title={'DNI aval'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="b_aval_reporte_sentinel" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Reporte sentinel</label>
                                <InputFile id={'b_aval_reporte_sentinel'} title={'Reporte sentinel aval'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="b_aval_recibo_servicio" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Recibo de servicios</label>
                                <InputFile id={'b_aval_recibo_servicio'} title={'Recibo de servicio'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="mb-2">
                                <label htmlFor="b_aval_documentos" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Documentos</label>
                                <InputFile id={'b_aval_documentos'} title={'Documentos'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                        </div>
                    </div>
                    :''
                    }
                    </div>
                    
                    </>:(formData.TIPO_EXPEDIENTE==='GARANTIA'?<div id='inputFiles'>
                        <label htmlFor="" className="text-[12px] text-primary-800 font-bold">Necesitamos conocer estos datos del crédito para el socio?</label>
                        <div className="grid lg:grid-cols-5 lg:gap-1 smm:grid-cols-3 smm:gap-1">
                            <div>
                                <select id={'GARA_FRECUENCIA'} value={formData.GARA_FRECUENCIA} onChange={handleChange} className="bg-primary-50 border border-primary-800 text-primary-800 text-sm rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5">
                                    <option >Frecuencia de pago</option>
                                    <option value="DIARIO">DIARIO</option>
                                    <option value="SEMANAL">SEMANAL</option>
                                    <option value="QUINCENAL">QUINCENAL</option>
                                    <option value="MENSUAL">MENSUAL</option>
                                </select>
                            </div>
                            <input placeholder="SCORE" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="GARA_SCORE"
                            value={formData.GARA_SCORE}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="# DE ENTIDADES" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="GARA_NUMENTIDADES"
                            value={formData.GARA_NUMENTIDADES}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="CAPACIDAD DE PAGO" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="GARA_CAPACIDADPAGO"
                            value={formData.GARA_CAPACIDADPAGO}
                            onChange={handleChange}
                            type="number"/>
                            <input placeholder="ENDEUDAMIENTO TOTAL" className="p-2 text-[10px] w-full m-1 rounded-md border-2 border-primary-800" 
                            id="GARA_ENDEUDAMIENTO"
                            value={formData.GARA_ENDEUDAMIENTO}
                            onChange={handleChange}
                            type="number"/>
                        </div>
                        <div  className='grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 mt-4'>
                            <div className="my-2">
                                <label htmlFor="solicitud_credito" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Solicitud de crédito</label>
                                <InputFile id={'garantia_solicitud_credito'} title={'Solicitud de crédito'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="my-2">
                                <label htmlFor="reporte_sentinel" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Reporte sentinel</label>
                                <InputFile id={'garantia_reporte_sentinel'} title={'Reporte sentinel'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="my-2">
                                <label htmlFor="dni" className="block mb-2 text-sm font-medium text-primary-900 uppercase">DNI</label>
                                <InputFile id={'garantia_dni'} title={'DNI'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                            <div className="my-2">
                                <label htmlFor="Certificado_deposito_contrato" className="block mb-2 text-sm font-medium text-primary-900 uppercase">Certificado</label>
                                <InputFile id={'garantia_Certificado_deposito_contrato'} title={'Certificado'} showToast={showToast} handleFileChangeIn={handleFileChangeIn}/>
                            </div>
                        </div>
                    </div>:'')               
                    }
                    {isSubmitting && (
                        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="block justify-center items-center" role="status">
                                <svg aria-hidden="true" className="w-8 h-8 text-secondary-400 animate-spin  fill-primary-800" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="text-primary-50 font-bold text-center">Procesando...</span>
                            </div>
                        </div>
                    )}
                    <div className="px-2 py-3 my-2 border-t-4 border-primary-800 text-right sm:px-6">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
