import { BrowserRouter, Routes, Route } from "react-router-dom";





import Login from "./components/login";
import Home from "./components/home";
import GeoDile from "./components/geoDile/inicio";
import ClubfamiliaDile from "./components/clubFamiliadile/inicio";
import Expedientes from "./components/expedientes/inicio";


//ROUTER ADMINISTRADOR
import Administrador from "./components/Administrador/inicio";
import Usuarios from "./components/Administrador/usuarios";
import Usuarios_SD from "./components/Administrador/usuariosSD";

//RUTA EXPEDIENTE_COLABORADOR
import MisExpedientes_cola from "./components/expedientes/MisExpedientes_cola";
import MisExpe_cola_mes from "./components/expedientes/MisExpe_cola_mes";
import MisSolicitudes_Analista from "./components/expedientes/MisSolicitudes_Analista";
import RevisarExpedientes from "./components/expedientes/RevisarExpedientes";
import RevisarExpedienteAgencia from "./components/expedientes/RevisarExpedienteAgencia";
import RevisarExpedientes_A_anio from "./components/expedientes/RevisarExpedientes_A_anio";
import RevisarExpedientes_A_ani_mes from "./components/expedientes/RevisarExpediente_A_anio_mes";
import RevisarExpedientes_A_anio_mes_select from "./components/expedientes/RevisarExpedientes_A_anio_mes_select";
import RevisarSolicitudes from "./components/expedientes/ResivarSolicitudes";


import MisExpediente_cola_mes_selected from "./components/expedientes/MisExpediente_cola_mes_selected";
import Usuarios_operaciones from "./components/Administrador/usuarios_operaciones";
import Usuarios_Recuperaciones from "./components/Administrador/usuarios_recuperaciones";
import Usuarios_Administrativos from "./components/Administrador/usuarios_administrativos";


function App() {
  return (
    <>
      <BrowserRouter basename="/">
        <>


          <Routes>
            <Route
              exact
              path="/"
              element={<Login/>}
            />

            <Route
              exact
              path="/home"
              element={<Home/>}
            />

            <Route exact path="/club-familia/inicio" element={<ClubfamiliaDile/>}/>
            <Route exact path="/geo-dile/inicio" element={<GeoDile/>}/>
            <Route exact path="/expedientes/inicio" element={<Expedientes/>}/>
            {/* ROUTER ADMINISTRADOR */}
            <Route exact path="/administrador/inicio" element={<Administrador/>}/>
            <Route exact path="/administrador/usuario" element={<Usuarios/>} />
            <Route exact path="/administrador/usuarios-operaciones" element={<Usuarios_operaciones/>} />
            <Route exact path="/administrador/usuarios-recuperaciones" element={<Usuarios_Recuperaciones/>} />
            <Route exact path="/administrador/usuarios-administrativos" element={<Usuarios_Administrativos/>}/>
            <Route exact path="/administrador/usuario-sd" element={<Usuarios_SD/>}/>

            {/**RUTA EXPEDIENTES */}
            <Route exact path="/expedientes/mis-expedientes" element={<MisExpedientes_cola/>}/>
            <Route path="/expedientes/mis-expedientes/:anio" element={<MisExpe_cola_mes/>}/>
            <Route path="/expedientes/mis-expedientes/:anio/:mes" element={<MisExpediente_cola_mes_selected/>}/>
            <Route path="/expedientes/mis-solicitudes" element={<MisSolicitudes_Analista/>}/>
            <Route path="/expedientes/revisar_expediente" element={<RevisarExpedientes/>}/>
            <Route path="/expedientes/revisar_expediente/:agencia" element={<RevisarExpedienteAgencia/>}/>
            <Route path="/expedientes/revisar_expediente/:agencia/:asesor" element={<RevisarExpedientes_A_anio/>}/>
            <Route path="/expedientes/revisar_expediente/:agencia/:asesor/:anio" element={<RevisarExpedientes_A_ani_mes/>}/>
            <Route path="/expedientes/revisar_expediente/:agencia/:asesor/:anio/:mes" element={<RevisarExpedientes_A_anio_mes_select/>}/>
            <Route path="/expedientes/revisar-solicitudes" element={<RevisarSolicitudes/>}/>

            <Route
              path="*"
              element={
                <div>
                  <p>Error 404</p>
                </div>
              }
            />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
