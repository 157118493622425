import React, { useContext, useEffect } from "react";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Transition,
} from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../UserContext.js';
import { Link, useNavigate } from 'react-router-dom'; // Importa el hook useNavigate
import { useState } from "react";
import ModalCambioClave from "../common/Modal/ModalCambioClave.jsx";

const navigation = [
    { name: 'Dashboard', href: '#', current: true },
    { name: 'Team', href: '#', current: false },
    { name: 'Projects', href: '#', current: false },
    { name: 'Calendar', href: '#', current: false },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
    const { userData, setUserData } = useContext(UserContext);
    const navigate = useNavigate(); // Inicializa el hook useNavigate
    const [isModalCambioClave,setIsModalCambioClave]=useState('');
    const [userInfo,setUserInfor]=useState('');

    const handleModalCambioClave=(user)=>{
        setIsModalCambioClave(true);
        setUserInfor(user);
    }
    const handleCloseModalCambioClave = () => {
        setIsModalCambioClave(false);
    };

    const handleSignOut = () => {
        // Elimina los datos del usuario del local storage
        localStorage.removeItem('userData');
        // Actualiza el estado de userData para reflejar que el usuario ha cerrado sesión
        setUserData(null);
        // Redirige al usuario a la página de inicio de sesión (o a cualquier otra página que prefieras)
        navigate('/'); // Asegúrate de tener una ruta /login en tu aplicación
    };

    return (
        <Disclosure as="nav" className="bg-primary-50 z-50 relative shadow-lg">
            {({ open }) => (
            <>
                <div className=" w-[100%] lg:px-8  smm:pl-3 flex">
                    <Link to={'/home'} className='w-full flex mt-3 justify-self-start'>
                        <img className='lg:w-32 lg:h-10 smm:w-20 smm:h-8 md:w-20' src={`${process.env.PUBLIC_URL}${'/images/logo1.png'}`}/>
                    </Link>
                    <div className="relative flex w-[800px] h-16 items-center justify-end">
                        <div className="relative inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                            <div className='smm:hidden lg:block mr-2 text-primary-800 items-center text-center border-r-2 border-primary-50 border-opacity-75 border-r-primary-50'>
                                <div className='p-4 smm:p-1'>
                                    <p className='text-[12px] font-light smm:text-[10px]'>{userData && userData.NOMBRE} {userData && userData.APE_PAT} {userData && userData.APE_MAT}</p>
                                    <p className='text-[10px] font-bold'>{userData && userData.AGENCIA}</p>
                                </div>
                            </div>
                            {/** }
                            <button
                                type="button"
                                className="relative rounded-full p-1 text-primary-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-800"
                            >
                                <span className="absolute -inset-1.5" />
                                <span className="sr-only">View notifications</span>
                                <BellIcon className="h-6 w-6" aria-hidden="true" />
                            </button>{*/}
            
                            {/* Profile dropdown */}
                            <Menu as="div" className="relative ml-3">
                                <div >
                                    <MenuButton className="relative flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2 focus:ring-offset-primary-800">
                                        <span className="absolute -inset-1.5" />
                                        <span className="sr-only">Open user menu</span>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/icons/icons_profile_user.svg`}
                                            loading="lazy"
                                            className="w-10 h-10"
                                        />
                                    </MenuButton>
                                </div>
                                <Transition
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                >
                                    <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="lg:hidden smm:border-b-2 smm:border-primary-800">
                                            <div className='block lg:mr-2 text-primary-800 items-center text-center border-r-2 border-primary-50 border-opacity-75 border-r-primary-50'>
                                                <div className='p-4 smm:p-1'>
                                                    <p className='text-[12px] font-light smm:text-[10px]'>{userData && userData.NOMBRE} {userData && userData.APE_PAT} {userData && userData.APE_MAT}</p>
                                                    <p className='text-[10px] font-bold smm:text-[10px]'>{userData && userData.AGENCIA}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <MenuItem>
                                        {({ focus }) => (
                                            <a
                                            href="#"
                                            className={classNames(focus ? 'bg-primary-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                                            >
                                            Mi perfil
                                            </a>
                                        )}
                                        </MenuItem>
                                        <MenuItem>
                                        {({ focus }) => (
                                            <button onClick={()=>handleModalCambioClave(userData)}
                                            className={classNames(focus ? 'bg-primary-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                                            >
                                                Cambio de clave
                                            </button>
                                        )}
                                        </MenuItem>
                                        <MenuItem>
                                        {({ focus }) => (
                                            <button
                                            onClick={handleSignOut} // Llama a la función handleSignOut al hacer clic
                                            className={classNames(focus ? 'bg-primary-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                                            >
                                            Cerrar sesión
                                            </button>
                                        )}
                                        </MenuItem>
                                    </MenuItems>
                                </Transition>
                            </Menu>
                        </div>
                    </div>
                </div>
    
                <DisclosurePanel className="sm:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2">
                    {navigation.map((item) => (
                    <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                    >
                        {item.name}
                    </DisclosureButton>
                    ))}
                </div>
                </DisclosurePanel>
                <ModalCambioClave
                isOpen={isModalCambioClave}
                onClose={handleCloseModalCambioClave}
                user={userInfo}
                />
            </>
            )}
        </Disclosure>
    );
}
