import React, { useEffect, useState , useContext } from "react";
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import CardExpediente_carpetas from "../common/card/CardExpediente_carpetas";
import { Link } from "react-router-dom";
import { UserContext } from '../UserContext.js';
import ModalAddAnio from "../common/Modal/ModalAddAnio.jsx";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../common/Loader.jsx";

export default function MisExpedientes_cola() {
    const [error, setError] = useState();
    const [data, setData] = useState([]);
    const {userData, setUserData } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const handleAddCarp = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

        const fetchData = async () => {
            if (!userData) return;

            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/mis-carpetas-anio', {
                    method: 'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({ user:userData.DNI })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                if (!result || !Array.isArray(result)) {
                    throw new Error('Invalid data format received');
                }
                setData(result); // Actualizar el estado con los datos recibidos
            } catch (error) {
                setError(error); // Actualizar el estado con el error si ocurre
            }finally{
                setIsLoading(false);
            }
        };
    
    
        useEffect(() => {
        fetchData();
    }, [userData]);

    const showToast = (message) => {
        setToastMessage(message);
        if(message=='existe'){
            toast.error('EL AÑO INGRESADO YA EXISTE.')
        }else{
            toast.success('CARPETA CREADA EXITOSAMENTE.');
            fetchData();
        }
        
    };

    return (
        <>
            {isLoading && <Loader />}
            <Navbar />
            <SidebarExpedientes />
            {!isLoading && (<>
            <div className="p-4 sm:ml-64">
                <h1 className="font-bold text-primary-800 m-2 text-xl">Mis Expedientes</h1>
                <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg">
                    <div className="inline-flex justify-between mb-4">
                        <div className="bg-secondary-200 rounded-lg">
                            <button onClick={() => handleAddCarp()} className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                                <img
                                    src={`${process.env.PUBLIC_URL}${'/icons/icons_add.svg'}`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                                <span className="flex-1 ms-3 whitespace-nowrap">Nuevo</span>
                            </button>
                        </div>
                    </div>
                    <div className="smm:w-[50%] lg:w-full lg:grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                        {data && data.map((item) => (
                            <>
                            <CardExpediente_carpetas
                                key={item._id.$oid}
                                icons={'/icons/icons_year.svg'}
                                link={`/expedientes/mis-expedientes/${item.nom_carp}`}
                                title={item.nom_carp}
                            />
                            </>
                        ))}
                    </div>
                </div>
                <ModalAddAnio
                cod="A" 
                title={'AGREGAR AÑO'} 
                description={'(*) De forma predeterminado cargara el año actual, si desea puede editar el año'} 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                showToast={showToast}/>
            </div>
            <ToastContainer />
            </>)}
        </>
    );
}
