import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../UserContext.js';
import Botton_MenuPrincipal from "../common/bottonMenu/botton_MenuPrincipal.jsx";

function SidebarExpedientes() {
  const { userData, setUserData } = useContext(UserContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [error, setError] = useState(null);
  const [buttons, setButtons] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga de datos

  const handleCloseSiderBar=()=>{
    setIsSidebarOpen(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!userData) return; // Esperar a que userData esté disponible

      try {
        const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/btn-user-expe', {
          method: 'POST',
          headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
          }),
          body: JSON.stringify({ user: userData.DNI })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const result = await response.json();
        setButtons(result.expediente);
      } catch (error) {
        setError(error); // Actualizar el estado con el error si ocurre
      } finally {
        setIsLoading(false); // Establecer isLoading en false cuando los datos hayan sido cargados
      }
    };

    fetchData();
  }, [userData]); // Dependencia en userData para volver a ejecutar cuando esté disponible

  return (
    <>
      <button
        data-drawer-target="sidebar-multi-level-sidebar"
        data-drawer-toggle="sidebar-multi-level-sidebar"
        aria-controls="sidebar-multi-level-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-primary-800 rounded-lg sm:hidden hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-gray-200"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>

      <aside
        id="sidebar-multi-level-sidebar"
        className={`bg-secondary-200 fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >

<div className='mt-16 mb-0 flex items-end justify-end'>
              <button
                data-drawer-target="sidebar-multi-level-sidebar"
                data-drawer-toggle="sidebar-multi-level-sidebar"
                aria-controls="sidebar-multi-level-sidebar"
                type="button"
                className="inline-flex justify-end items-end p-2 text-sm text-primary-800 rounded-lg lg:hidden hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-gray-200"
                onClick={() => setIsSidebarOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"/>
                </svg>
              </button>
          </div>

        {/* Sidebar content */}
        <div className="h-full px-8 py-4 overflow-y-auto  shadow-lg">
          {!isLoading && (
            <>
              <ul className="space-y-4 font-medium">
                {buttons.map(button => (
                  <Botton_MenuPrincipal
                    key={button.cod_btn}
                    title={button.title}
                    icons={button.icons}
                    link={button.link}
                    isDisabled={button.isDisabled}
                  />
                ))}
              </ul>
              <ul className='mt-[100%]'>
                {buttons.length > 0 && (
                  <Botton_MenuPrincipal
                    title={'Salir'}
                    icons={'/icons/icons_out.svg'}
                    link={'/home'}
                    isDisabled={true}
                  />
                )}
              </ul>
            </>
          )}
        </div>
      </aside>
    </>
  );
}

export default SidebarExpedientes;
