import React, { useState, useContext, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import NavBar from '../navbar/navBar';
import { UserContext } from '../UserContext.js';
import { Button } from '@headlessui/react';
import ModalVerificarUbicacion from '../common/Modal/ModalVerificarUbicacion.jsx';
import ModalGenerarReportUbicacion from '../common/Modal/ModalGenerarReportUbicacion.jsx';
import { Position } from '@react-pdf-viewer/core';



export default function Inicio() {
    const { userData } = useContext(UserContext);
    const [position, setPosition] = useState(null);
    const [locate, setLocate] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalReportOpen,setIsModalReportOpen]=useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [coordinates, setCoordinates] = useState([]);
    const [coorMiAgencia,setCoorMiAgencia]=useState('');

    const customIconDomicilio = new L.Icon({
        iconRetinaUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_domicilio.png',  // URL para la versión de alta resolución
        iconUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_domicilio.png',        // URL para la versión normal
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',      // URL para la sombra del icono (opcional)
        iconSize: [25, 41],                       // Tamaño del icono [ancho, alto]
        iconAnchor: [12, 41],                     // Punto del icono que apunta a la ubicación [ancho, alto]
        popupAnchor: [1, -34],                    // Punto desde donde aparece el popup relativo al icono [ancho, alto]
        shadowSize: [41, 41]                      // Tamaño de la sombra (opcional)
    });

    
    const customIconNegocio = new L.Icon({
        iconRetinaUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_negocio.png',  // URL para la versión de alta resolución
        iconUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_negocio.png',        // URL para la versión normal
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',      // URL para la sombra del icono (opcional)
        iconSize: [25, 41],                       // Tamaño del icono [ancho, alto]
        iconAnchor: [12, 41],                     // Punto del icono que apunta a la ubicación [ancho, alto]
        popupAnchor: [1, -34],                    // Punto desde donde aparece el popup relativo al icono [ancho, alto]
        shadowSize: [41, 41]                      // Tamaño de la sombra (opcional)
    });

    const customIconMiAgencia = new L.Icon({
        iconRetinaUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_mi_agencia.png',  // URL para la versión de alta resolución
        iconUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_mi_agencia.png',        // URL para la versión normal
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',      // URL para la sombra del icono (opcional)
        iconSize: [25, 41],                       // Tamaño del icono [ancho, alto]
        iconAnchor: [12, 41],                     // Punto del icono que apunta a la ubicación [ancho, alto]
        popupAnchor: [1, -34],                    // Punto desde donde aparece el popup relativo al icono [ancho, alto]
        shadowSize: [41, 41]                      // Tamaño de la sombra (opcional)
    });

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleVerNivelesPorMora=()=>{

    }
    const handleSubmitSearch = () => {
        
    }
    const handleCreateReporteUbicación=(e)=>{
        e.preventDefault();
        setIsModalReportOpen(true);
    }
    const handleCloseReportModal = () => {
        setIsModalReportOpen(false);
    }

    // Cargar coordenadas desde la API al iniciar el mapa
    const cargarCoordenadas = async () => {
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/coordenadas_por_user', {
                method: 'POST',
                headers: { "ngrok-skip-browser-warning": "69420" },
                body: JSON.stringify({user:userData.DNI })
            });
            const dataC = await response.json();
            if(dataC.status===true){
                setCoorMiAgencia([dataC.lat_agencia,dataC.lng_agencia])
                console.log(coorMiAgencia);
                setCoordinates(dataC.data);
            }else{
                setCoorMiAgencia([dataC.lat_agencia,dataC.lng_agencia])
                console.log(coorMiAgencia);
                setCoordinates(0)
            }
        } catch (error) {
            console.error('Error al cargar las coordenadas:', error);
        }
    };


    useEffect(() => {
        cargarCoordenadas();
        // Configuración del ícono predeterminado de Leaflet
        delete L.Icon.Default.prototype._getIconUrl;
        //L.Icon.Default.mergeOptions({
           // iconRetinaUrl: 'https://map.escuelagenial.com/images/marker_color/marcador_negocio.png',
           // iconUrl: 'https://map.escuelagenial.com/images/marker_color/marker_riesgo/marker_normal.png',
          //  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
        //});
    }, [userData]);

    function LocationMarker() {
        const map = useMap();

        useEffect(() => {
            if (locate) {
                map.locate();
            }
        }, [locate, map]);

        useMapEvents({
            locationfound(e) {
                setPosition(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
                setLocate(false); // Resetear el estado de localización
            },
        });

        return position === null ? null : (
            <Marker position={position} icon={customIconDomicilio}>
                <Popup>You are here</Popup>
            </Marker>
        );
    }

    const handleVerificarVivienda = async (e) => {
        e.preventDefault();
        // Detectar si el usuario está usando un dispositivo móvil
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            // Pedir permiso para acceder a la ubicación
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocate(true);
                        setIsModalOpen(true);
                    },
                    (error) => {
                        alert('Error al acceder a la ubicación. Asegúrate de que el GPS esté activado y los permisos de ubicación estén habilitados.');
                    },
                    { timeout: 10000 }
                );
            } else {
                alert('Tu navegador no soporta la geolocalización.');
            }
        } else {
            alert("Esta función está disponible solo en dispositivos móviles.");
            setIsModalOpen(false);
        }
    };
    
    return (
        <div className="">
            <NavBar />           

            <div className="w-full">
                <div>
                        <div className="z-50 group fixed top-16 left-16 p-2 flex items-start justify-start w-24 h-24 ">
                            <div className="mx-auto max-w-md rounded-full bg-primary-50">
                            <form action="" className="relative mx-auto w-max">
                                <input 
                                value={FormData.DNI}
                                onBlur={handleSubmitSearch}
                                type="search" 
                                className="text-primary-800 peer cursor-pointer relative z-10 h-12 w-12 rounded-full border-2 bg-primary-50 border-primary-800 bg-transparent pl-12 outline-none focus:w-full focus:cursor-text focus:border-primary-800 focus:pl-16 focus:pr-4" 
                                />
                                <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                className="absolute inset-y-0 my-auto h-8 w-12  border-r border-transparent stroke-primary-800 px-3.5 peer-focus:border-primary-800 peer-focus:stroke-primary-800" 
                                fill="none" 
                                viewBox="0 0 24 24" 
                                stroke="currentColor" 
                                strokeWidth="2"
                                >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </form>
                            </div>
                        </div>

                </div>
            <MapContainer 
                    center={[-13.524643, -71.950307]} //-13.522657, -71.969723
                    zoom={13} 
                    scrollWheelZoom={true}
                    style={{ height: '90vh', width: '100%', zIndex: 0}} 
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <LocationMarker/>
                    {coordinates.length>0? (
                        coordinates.map((coordenada, index) => (
                            coordenada.tipo_ubicacion === 'DOMICILIO' ? (
                                <Marker key={index} position={[coordenada.lat, coordenada.lng]} icon={customIconDomicilio}>
                                    <Popup>
                                        <p>{coordenada.nom_socio}</p>
                                    </Popup>
                                </Marker>
                            ) : (
                                <Marker key={index} position={[coordenada.lat, coordenada.lng]} icon={customIconNegocio}>
                                    <Popup>
                                        <p>{coordenada.nom_socio}</p>
                                    </Popup>
                                </Marker>
                            )
                        ))
                    ) : (
                        ''
                    )}
                </MapContainer>

                {/**BUTTON FLOATING BOTONES DE INGRESAR UBICACIÓN - SACAR REPORTE DE UBICACION - VER MI MAPA */}
                <div className="z-50 group fixed top-40 left-1 p-2 flex items-start justify-start w-24 h-24">
            
                    <Button className="text-white shadow-xl flex items-center justify-center p-3 rounded-full bg-gradient-to-r from-primary-500 to-primary-800 z-50 absolute">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 group-hover:rotate-90 transition-all duration-[0.6s]">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    </Button>
                    {/* Sub left */}
                    <Button onClick={(e)=>handleVerificarVivienda(e)}
                    className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-y-0 group-hover:scale-y-100 group-hover:translate-y-16 flex p-2 hover:p-3 bg-green-600 scale-100 hover:bg-green-800 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48" stroke="currentColor" fill='currentColor'>
                            <path fill-rule="evenodd" d="M22.5 15.5h3v-2h-3v2zM39 37.985l-7.5-2.251V18.367l7.5 2.25v17.368zM29.55 14.1L28 12.938V18.5h-8v-5.562L18.45 14.1l-.9-1.2L24 8.064l2.5 1.875V9H28v2.063l2.45 1.838-.9 1.199zM30 35.569L18.5 37.78V20.506C20.847 24.829 24 29 24 29s3.63-4.803 6-9.444v16.013zm-13 2.3l-8-1.598V18.016l8 2.4V37.87zm14.728-22.566c.171-.656.272-1.267.272-1.808C32 8.57 29.054 6 23.973 6 18.892 6 16 8.57 16 13.495c0 1.05.367 2.362.94 3.77L6 13.986V38.73l11.494 2.299 12.928-2.486L42 42.016V18.384l-10.272-3.081z"/>
                        </svg>
                    </Button>
                    {/* Sub top */}
                    {/**VER MIS NIVELES DE MORA POR MAPA */}
                    <Button  onClick={handleVerNivelesPorMora()}
                    className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:translate-x-16 flex p-2 hover:p-3 bg-primary-700 hover:bg-blue-800 text-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 15C9 11.6863 11.6863 9 15 9C18.3137 9 21 11.6863 21 15C21 18.3137 18.3137 21 15 21C11.6863 21 9 18.3137 9 15ZM12.8448 13.1446C13.0806 12.6017 13.5084 12.1657 14.0454 11.9194C14.0865 11.4048 14.5171 11 15.0422 11C15.5584 11 15.9832 11.3911 16.0366 11.8931C16.4628 12.0439 16.8457 12.3008 17.148 12.6416C17.5146 13.0547 17.4768 13.6868 17.0636 14.0533C16.6505 14.4198 16.0185 14.382 15.6519 13.9689C15.5571 13.8619 15.431 13.7875 15.2916 13.756C15.2535 13.7474 15.2159 13.7366 15.1791 13.7236C15.0837 13.6899 14.979 13.6937 14.8862 13.7341C14.8491 13.7503 14.815 13.7719 14.7848 13.7979C14.8729 13.8954 15.0351 14.0025 15.24 14.0524C15.8263 14.1952 16.3666 14.5156 16.7442 14.9529C17.1175 15.3853 17.4125 16.044 17.2078 16.7747C17.1955 16.8188 17.1801 16.862 17.1619 16.9039C16.936 17.423 16.5346 17.8444 16.0301 18.0955C15.9552 18.5736 15.5414 18.9394 15.0422 18.9394C14.5761 18.9394 14.1845 18.6206 14.0736 18.189C13.5998 18.0423 13.1746 17.7666 12.8467 17.3909C12.4835 16.9749 12.5263 16.3432 12.9424 15.98C13.3584 15.6168 13.9901 15.6596 14.3533 16.0757C14.4483 16.1844 14.5754 16.2603 14.7162 16.2922C14.7538 16.3007 14.7909 16.3114 14.8272 16.3242C14.9228 16.3579 15.0276 16.3541 15.1205 16.3136C15.1575 16.2975 15.1915 16.276 15.2216 16.2501C15.1323 16.1518 14.9697 16.045 14.7668 15.9956C14.1817 15.8531 13.6419 15.5344 13.2639 15.0969C12.889 14.6631 12.598 14.007 12.7975 13.2788C12.8101 13.233 12.8258 13.1881 12.8448 13.1446ZM14.7291 13.7183C14.7291 13.7183 14.7307 13.7209 14.7319 13.7258C14.7294 13.7208 14.7291 13.7183 14.7291 13.7183ZM15.2789 16.3314C15.2789 16.3314 15.2773 16.3288 15.276 16.3237C15.2785 16.3289 15.2789 16.3314 15.2789 16.3314Z"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M4 14C4.55228 14 5 14.4477 5 15V19C5 19.5523 4.55228 20 4 20C3.44772 20 3 19.5523 3 19V15C3 14.4477 3.44772 14 4 14Z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 12C7.55228 12 8 12.4477 8 13V19C8 19.5523 7.55228 20 7 20C6.44772 20 6 19.5523 6 19V13C6 12.4477 6.44772 12 7 12Z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 4C13.5 3.44772 13.9477 3 14.5 3H18C18.5523 3 19 3.44772 19 4V7C19 7.55228 18.5523 8 18 8C17.4477 8 17 7.55228 17 7V6.20377L14.6585 8.25258C14.3094 8.558 13.7962 8.58332 13.4188 8.31373L10.5254 6.24708L6.61394 9.28935C6.17799 9.62842 5.54972 9.54989 5.21065 9.11394C4.87158 8.67799 4.95011 8.04972 5.38606 7.71065L9.88606 4.21065C10.2352 3.93908 10.7213 3.92917 11.0812 4.18627L13.9372 6.22621L15.3386 5H14.5C13.9477 5 13.5 4.55228 13.5 4Z"/>
                    </svg>

                    </Button>
                    {/* Sub middle */}
                    <Button onClick={(e)=>handleCreateReporteUbicación(e)}
                    className="absolute rounded-full transition-all duration-[0.2s] ease-out scale-x-0 group-hover:scale-x-100 group-hover:translate-x-14 group-hover:translate-y-14 flex p-2 hover:p-3 bg-yellow-500 hover:bg-yellow-600 text-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 2.22117V7H4.22117C4.31517 6.81709 4.43766 6.64812 4.58579 6.5L8.5 2.58579C8.64812 2.43766 8.81709 2.31517 9 2.22117ZM11 2V7C11 8.10457 10.1046 9 9 9H4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H11ZM11.3944 11.5528C11.2195 11.2029 10.8566 10.9871 10.4656 11.0006C10.0746 11.0141 9.72739 11.2543 9.57693 11.6154L7.07693 17.6154C6.94833 17.924 6.98249 18.2765 7.16795 18.5547C7.35342 18.8329 7.66565 19 8 19H16C16.3466 19 16.6684 18.8205 16.8507 18.5257C17.0329 18.2309 17.0494 17.8628 16.8944 17.5528L14.8944 13.5528C14.7394 13.2428 14.435 13.0352 14.0898 13.004C13.7446 12.9729 13.408 13.1227 13.2 13.4L12.6708 14.1056L11.3944 11.5528ZM13 9.5C13 8.67157 13.6716 8 14.5 8C15.3284 8 16 8.67157 16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5Z"/>
                    </svg>

                    </Button>
                </div>

                {/**BOTON DE LOCALIZAR MI UBICACIÓN */}
                <button
                onClick={() => setLocate(true)}
                className="z-50 group fixed bottom-0 right-0 p-2 flex items-start justify-start w-24 h-24">
                    <div className="text-primary-800 shadow-xl shadow-primary-800 flex items-center justify-center p-3 rounded-full bg-gradient-to-r from-primary-50 to-primary-100 border-2 border-primary-800 z-50 absolute">
                        <svg
                            version="1.1"
                            id="Capa_1"
                            stroke="currentColor"
                            fill="currentColor"
                            x="0px"
                            y="0px"
                            className="w-6 h-6 group-hover:rotate-90 transition-all duration-[0.6s]"
                            width="200px"
                            height="200px"
                            viewBox="0 0 561 561"
                            xmlSpace="preserve"
                        >
                            <g id="gps-fixed">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M280.5,178.5c-56.1,0-102,45.9-102,102c0,56.1,45.9,102,102,102c56.1,0,102-45.9,102-102
                                C382.5,224.4,336.6,178.5,280.5,178.5z M507.45,255C494.7,147.9,410.55,63.75,306,53.55V0h-51v53.55
                                C147.9,63.75,63.75,147.9,53.55,255H0v51h53.55C66.3,413.1,150.45,497.25,255,507.45V561h51v-53.55
                                C413.1,494.7,497.25,410.55,507.45,306H561v-51H507.45z M280.5,459C181.05,459,102,379.95,102,280.5S181.05,102,280.5,102
                                S459,181.05,459,280.5S379.95,459,280.5,459z"
                            />
                            </g>
                        </svg>
                    </div>
                </button>
            </div>
            
            <ModalVerificarUbicacion
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            coord={position}
            />
            <ModalGenerarReportUbicacion
            isOpen={isModalReportOpen}
            onClose={handleCloseReportModal}
            />
        </div>
    );

};