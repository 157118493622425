import React, { useState } from "react";

export default function InputFile({ title, showToast, handleFileChangeIn }) {
    const [fileName, setFileName] = useState('');

    const truncateFileName = (name, maxLength = 20) => {
        if (name.length <= maxLength) return name;
        const extension = name.split('.').pop();
        const nameWithoutExtension = name.slice(0, -(extension.length + 1));
        const truncatedName = nameWithoutExtension.slice(0, maxLength - extension.length - 3) + '...';
        return `${truncatedName}.${extension}`;
    };

    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.type === 'application/pdf') {
                setFileName(truncateFileName(file.name));
                handleFileChangeIn(title, file); // Pasar el archivo al componente padre
            } else {
                showToast('PDF_FAIL');
            }
        }
    };

    return (
        <div className="w-full py-4 bg-primary-50 rounded-2xl border border-primary-800 gap-3 grid border-dashed">
            <div className="grid gap-1">
                <h2 className="text-center text-gray-400 text-xs leading-4">
                    Solo PDF, Tamaño maximo 10MB
                </h2>
            </div>
            <div className="grid gap-2">
                <h4 className={fileName ? "text-center text-primary-800 text-[10px] font-normal" : "text-center text-primary-800 text-sm font-medium leading-snug"}>
                    {fileName ? fileName : `Cargar ${title}`}
                </h4>
                <div className="flex items-center justify-center">
                    <label>
                        <input
                            type="file"
                            hidden
                            onChange={handleFileChange}
                        />
                        <div className={`flex w-28 h-9 px-2 flex-col rounded-full shadow text-white text-xs font-semibold leading-4 items-center justify-center cursor-pointer focus:outline-none ${fileName ? 'bg-green-500' : 'bg-secondary-400'}`}>
                            {fileName ? 'Listo' : 'Cargar'}
                        </div>
                    </label>
                </div>
            </div>
        </div>
    );
}
