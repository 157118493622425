import React ,{ useEffect, useState , useContext }from "react";
import { UserContext } from '../UserContext.js';
import { useParams } from 'react-router-dom';
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import Navbar from "../navbar/navBar";
import CardExpediente_carpetas from "../common/card/CardExpediente_carpetas";
import ModalAddAnio from "../common/Modal/ModalAddAnio.jsx";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../common/Loader.jsx";

export default function MisExpe_cola_mes(){
    const { anio } = useParams();
    const [error, setError] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const { userData, setUserData } = useContext(UserContext);
    const [toastMessage, setToastMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    


    const handleAddCarp = () => {
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    
        const fetchData = async () => {
            if (!userData) return;

            try {
                const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/mis-carpetas-anio-mes', {
                    method: 'POST',
                    headers: new Headers({
                        "ngrok-skip-browser-warning": "69420",
                    }),
                    body: JSON.stringify({ user:userData.DNI, anio:anio })
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                if (!result || !Array.isArray(result)) {
                    throw new Error('Invalid data format received');
                }
                setData(result); // Actualizar el estado con los datos recibidos
            } catch (error) {
                setError(error); // Actualizar el estado con el error si ocurre
            }finally{
                setIsLoading(false);
            }
        };

    useEffect(() => {
        fetchData();
    }, [userData]);

    const showToast = (message) => {
        setToastMessage(message);
        if(message=='existe'){
            toast.error('EL MES INGRESADO YA EXISTE.')
        }else{
            toast.success('CARPETA CREADA EXITOSAMENTE.');
            fetchData();
        }
        
    };

    return(<>
        {isLoading && <Loader />}
            <Navbar/>
            <SidebarExpedientes/>
            {!isLoading && (<>
            <div className="p-4 sm:ml-64">
                <h1 className="font-light text-primary-800 m-2 text-xl">{"Mis Expedientes >"}<span className="font-bold">{anio}</span></h1>
                <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg">
                    <div className="inline-flex justify-between mb-4">
                        <div className="bg-secondary-200 rounded-lg">
                            <button onClick={() => handleAddCarp()} className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group">
                                <img
                                    src={`${process.env.PUBLIC_URL}${'/icons/icons_add.svg'}`}
                                    loading="lazy"
                                    className="w-6 h-6"
                                />
                                <span className="flex-1 ms-3 whitespace-nowrap">Nuevo</span>
                            </button>
                        </div>
                    </div>
                    <div className="smm:w-[80%] lg:w-full  lg:grid lg:grid-cols-4 lg:gap-4 smm:grid-cols-1 smm:justify-center smm:items-center">
                        {data && data.map((item) => (
                            <>
                            <CardExpediente_carpetas
                                key={item._id.$oid}
                                icons={'/icons/icons_month.svg'}
                                link={`/expedientes/mis-expedientes/${item.anio}/${item.nom_mes}`}
                                title={item.nom_mes}
                            />
                            </>
                        ))}
                    </div>
                </div>
                <ModalAddAnio
                cod="M"
                title={'AGREGAR MES'} 
                description={'(*) De forma predeterminado cargara el mes actual, si desea puede editar el mes'} 
                isOpen={isModalOpen} 
                onClose={handleCloseModal} 
                showToast={showToast}/>
            </div>
            <ToastContainer />
            </>)}
    </>
    );        
}